import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Footer } from "./components/Footer";
import { renderRoutes } from './routes/RouteUtils';
import routes from './routes/routes';
//import routes from './routes/routes';

function App() {
  return (
    <>
      <CssBaseline />
      <Router>
        <Switch>
          <Route path="/login">
            {/* Componente de inicio de sesión*/}
          </Route>
          <Route path="/">
            <div className="App">
              <NavBar />
              <Banner />
              <Projects />
              <Skills />
              <Footer />
            </div>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
