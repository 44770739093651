import React, { lazy } from 'react'
import { HomeRedirect } from './RouteUtils'
import RouteController from './RouteController'
const Dashboard = lazy(() => import('../components/views/Dashboard'))
const Login = lazy(() => import('../components/Login'))
const Home = lazy(() => import('../components/views/Home'))

const routes = [
    {
      path: '/',
      exact: true,
      component: HomeRedirect,
    },
    {
      path: '/Login',
      exact: true,
      render: (props) => <Login {...props} />,
    },
    {
      path: '/Juego',
      render: (props) => <RouteController component={Home} {...props} />,
      roles: ['admin', 'jugador'], // Roles autorizados para acceder a esta ruta
      routes: [
        {
          path: '/Juego',
          exact: true,
          render: (props) => <RouteController component={Dashboard} {...props} />,
          roles: ['admin', 'jugador'], // Roles autorizados para acceder a esta subruta
        },
      ],
    },
  ];
  
  export default routes;