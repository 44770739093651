import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { renderRoutes } from './routesAdmin/RouteUtils';
import routes from './routesAdmin/routesAdmin';
import { CssBaseline } from '@material-ui/core';

const Admin = () => {
  return (
    <>
      <CssBaseline />
      <Router>
        <Suspense fallback={<p>loading...</p>}>
          {renderRoutes(routes)}
        </Suspense>
      </Router>
    </>
  );
};

export default Admin;
