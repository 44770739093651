import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
      <Col lg={12}>
        <div className="newsletter-bx" style={{alignItems: 'center', justifyContent: 'center' }}>
        <h3 >¡Adéntrate en un mundo de magia y misterio en "El Reino de los Idiomas Olvidados"! En este emocionante videojuego medieval, te unirás a un valiente soldado, una astuta princesa y un poderoso mago en un viaje épico por tierras desconocidas.

          Juntos, enfrentarán desafíos inimaginables mientras aprenden el arte de la lengua inglesa. En cada misión, deberán resolver enigmas, superar obstáculos y luchar contra criaturas mágicas, todo mientras dominan un nuevo idioma.

          La magia de la aventura te espera mientras descubres secretos ancestrales, desbloqueas poderes ocultos y te conviertes en un verdadero héroe lingüístico. ¿Estás listo para el desafío? ¡Únete a la búsqueda y conquista el Reino de los Idiomas Olvidados!
        </h3>
        </div>
      </Col>
  )
}