import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import projImg1 from "../assets/img/Grupo-metis.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "GRUPO METIS",
      description: "Grupo de Investigación en Informática Organizacional",
      imgUrl: projImg1,
    },
    
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>GRUPO METIS</h2>
                
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-3 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">VISIÓN</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">LINEAS DE INVESTIGACION</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                      <p style={{alignItems: 'flex-end', justifyContent: 'flex-start',  width: 700 }}>El Grupo METIS será una organización que estará conformado por estudiantes y profesores, quienes a través del Semillero de Investigación del Proyecto Curricular, han adquirido formación investigativa para participar en la formulación de proyectos, investigación y propuestas de aplicación de conocimientos, que se clasificaran y fortalecerán el trabajo en las líneas de investigación del área de conocimiento en Informática Organizacional, con el propósito de generar conocimiento, publicaciones, fortalecer el pensum académico del Proyecto Curricular y poner a disposición de la Comunidad el trabajo realizado a través del Centro de Conocimiento.
                      </p>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <p style={{alignItems: 'flex-end', justifyContent: 'center',  width: 400 }}></p>
                      <p>- Accesibilidad Web</p>
                      <p>- Análisis de Movimiento Humano</p>
                      <p>- Gestión del Conocimiento</p>
                      <p>- Informática Organizacional</p>
                      <p>- Innovación Y Emprendimiento</p>
                      <p>- Nuevas Tecnologías</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}