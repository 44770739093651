import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import logo from '../assets/img/logomedieval.png';
import { Link } from "react-router-dom";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Inicio</Nav.Link>
            <Nav.Link href="#project" className={activeLink === 'project' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('project')}>Grupo Metis</Nav.Link>
            <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Proyecto</Nav.Link>
            <NavDropdown
              title="Validacion"
              className={`${activeLink === 'validacion' ? 'active navbar-link' : 'navbar-link'} customNavDropdown`}
              style={{
                fontWeight: 600,
                // color: '#fff', // Establece el color blanco para el título
                letterSpacing: '0.8px',
                padding: '0 25px',
                fontSize: '20px'

              }}
              onClick={() => onUpdateActiveLink('validacion')}
            >
              <NavDropdown.Item href="#" className="navbar-link">Estudiante UD</NavDropdown.Item>
              <NavDropdown.Item href="#" className="navbar-link">Niño</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/login" className={activeLink === 'Login' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Login')} target="_blank">Inicia Sesión</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
