import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import './css/index.css';
import App from './App';
import Juego from './Juego'; 
import Admin from './Admin'; 
import Login from './components/Login';
import reportWebVitals from './reportWebVitals';
import { MainContextProvider } from './Context/MainContext';
import { CssBaseline } from '@mui/material';
import theme from './theme/theme';

const root = createRoot(document.getElementById('root'));

root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <MainContextProvider>
        <CssBaseline />
        <HelmetProvider>
          <React.StrictMode>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/juego" component={Juego} />
              <Route path="/admin" component={Admin} />
              <Route path="/" component={App} />
            </Switch>
          </React.StrictMode>
        </HelmetProvider>
      </MainContextProvider>
    </ThemeProvider>
  </Router>
);

reportWebVitals();
