// Dashboard.jsx
import React, { useRef, useState } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import Page from '../../commonAdmin/Page';
import AdminImage from './Admin.jpg';
import MainMenu from '../../layoutAdmin/MainMenu';
import Usuarios from '../../layoutAdmin/Usuarios';

const Dashboard = () => {
    const imagenRef = useRef();
    const [showUsers, setShowUsers] = useState(false);
    const [open, setOpen] = useState(false);

    const handleShowUsers = () => {
        setShowUsers(true);
    };

    return (
        <Page title="MEDIEVAL | Dashboard">
            <Container maxWidth="xl">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white' }}>
                    <Typography sx={{ mt: 1, fontWeight: 'bold', fontFamily: 'Arial, sans-serif', textTransform: 'uppercase', letterSpacing: '2px' }} variant='h5'>
                        Bienvenido a
                    </Typography>
                    <Typography sx={{ mt: 0, fontWeight: 'bold', fontFamily: 'Arial, sans-serif', textTransform: 'uppercase', letterSpacing: '2px' }} variant='h2'>
                        MEDIEVAL ADMIN
                    </Typography>
                </Box>
                <MainMenu open={open} setOpen={setOpen} handleShowUsers={handleShowUsers} />
                <Usuarios />
               {/* <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
                    <Grid item xs={12} sm={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                            <img ref={imagenRef} src={AdminImage} alt='...' style={{ width: '50%', height: 'auto' }} />
                        </Box>
                    </Grid>
                </Grid> */}
            </Container>
        </Page>
    )
}

export default Dashboard;
