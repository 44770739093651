import React, { useState, useEffect } from 'react'
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Container, Typography, Grid, Box, Button, Stack, Avatar, IconButton, Divider, MenuItem } from '@mui/material'
import ApiRequest from '../../../helpers/axiosInstances'
import { AddOutlined, EditOutlined, DeleteOutline } from '@mui/icons-material'
import Page from '../../common/Page'
import ToastAutoHide from '../../common/ToastAutoHide'
import CommonTable from '../../common/CommonTable'

const Usuarios = () => {
	const initialState = {
		Nombre_Usuario: "",
		Fecha_Registro: "",
		Contrasena: "",
		id_Rol: "",
		Nombre_Jugador: "",
		Fecha_Nacimiento: "",
		nivel: "",
		Rol: ""
	}
	const [usuariosList, setUsuariosList] = useState([])
	const [body, setBody] = useState(initialState)
	const [openDialog, setOpenDialog] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [mensaje, setMensaje] = useState({ ident: null, message: null, type: null })

	const init = async () => {
		try {
			const { data } = await ApiRequest().get('/api/usuario');
			const usuariosConId = data.map((usuario, index) => ({
				...usuario,
				IdUsuario: usuario.Id_Usuario, // Asigna el valor del campo Id_Usuario como identificador único
				Fecha_Nacimiento: usuario.Fecha_Nacimiento.split('T')[0], // Extraer solo la fecha de nacimiento (sin la hora)
				Fecha_Registro: usuario.Fecha_Registro.split('T')[0], // Extraer solo la fecha de registro (sin la hora)
			}));
	
			setUsuariosList(usuariosConId);
			console.log(data);
		} catch (error) {
			console.error("Error fetching usuarios:", error);
		}
	};
	

    useEffect(() => {
        init();
    }, []); // <-- Dependencia vacía para que se ejecute solo una vez al montar el componente


	const columns = [
		{ field: 'Id_Usuario', headerName: 'Id Usuario', width: 120 },
		{ field: 'Nombre_Usuario', headerName: 'Nombre Usuario', width: 220 },
		{ field: 'Fecha_Registro', headerName: 'Fecha Registro', width: 220 },
		{ field: 'Contrasena', headerName: 'Contraseña', width: 220 },
		{ field: 'Nombre_Jugador', headerName: 'Nombre Jugador', width: 220 },
		{ field: 'Fecha_Nacimiento', headerName: 'Fecha Nacimiento', width: 220 },
		{ field: 'Nivel', headerName: 'Nivel', width: 220 },
		{ field: 'Rol', headerName: 'Rol', width: 220 },
		{
			field: '', 
			headerName: 'Acciones',
			width: 200,
			renderCell: (params) => (
				<Stack divider direction='row' divider={<Divider orientation="vertical" flexItem />} justifyContent="center" alignItems="center" spacing={2}>
					<IconButton size='small' onClick={() => {
						setIsEdit(true)
						setBody(params.row)
						handleDialog()
					}}>
						<EditOutlined />
					</IconButton>
					<IconButton size='small' onClick={() => onDelete(params.row.Id_Usuario)}>
						<DeleteOutline />
					</IconButton>

				</Stack>
			)
		}
	]

	const roles = [
		{ value:  'Admin', label: 'Admin' },
		{ value: 'Jugador', label: 'Jugador' },
	  ];

	  const onDelete = async (IdUsuario) => {
		try {
		  const { data } = await ApiRequest().post('/api/eliminar', { IdUsuario });
		 
		  setMensaje({
			ident: new Date().getTime(),
			message: data.message,
			type: 'success'
		  });
		  init();
		} catch ({ response }) {
		  setMensaje({
			ident: new Date().getTime(),
			message: response?.data?.sqlMessage || 'Hubo un error al eliminar el usuario',
			type: 'error'
		  });
		}
	  };
	  
	

	const handleDialog = () => {
		setOpenDialog(prev => !prev)
	}

	const onChange = ({ target }) => {
		const { name, value } = target; // Obtener el nombre y el valor del campo
	
		// Si el campo es de fecha de nacimiento, obtener solo la fecha (sin la hora)
		const formattedValue = name === 'Fecha_Nacimiento' ? value.split('T')[0] : value;
	
		setBody({
			...body,
			[name]: formattedValue // Actualizar el estado con el nombre del campo como clave
		});
	};
	
	const onSubmit = async () => {
		try {
			// Hacer el primer insert en la tabla 'usuario'
			const { Nombre_Usuario, Contrasena, Rol } = body;
			const idRol = Rol === 'Admin' ? 1 : 2;
	
			const responseUsuario = await ApiRequest().post('/api/guardar', { Nombre_Usuario, Contrasena, idRol });
	
			if (responseUsuario.status !== 200) {
				throw new Error('Hubo un error al crear el usuario');
			}
	
			const idUsuario = responseUsuario.data.idUsuario; // Suponiendo que el servidor devuelve el ID del usuario generado
			
			// Hacer el segundo insert en la tabla 'Jugador' incluyendo el idUsuario generado
			await ApiRequest().post('/api/guardarJugador', { ...body, Id_Usuario: idUsuario });
			
			// Restablecer el estado del formulario y mostrar mensaje de éxito
			handleDialog();
			setBody(initialState);
			setMensaje({
				ident: new Date().getTime(),
				message: 'Usuario creado exitosamente',
				type: 'success'
			});
			init();
			setIsEdit(false);
		} catch ({ response }) {
			setMensaje({
				ident: new Date().getTime(),
				message: response?.data?.sqlMessage || 'Hubo un error al crear el usuario',
				type: 'error'
			});
		}
	};
	
	const onEdit = async () => {
		try {
			// Mapear el valor del rol seleccionado al ID de rol correspondiente
			const idRol = body.Rol === 'Admin' ? 1 : 2;
	
			// Crear un nuevo objeto con los datos actualizados
			const updatedBody = {
				...body,
				Id_Rol: idRol
			};
	
			// Realizar la petición para editar en la tabla 'usuarios' con los datos actualizados
			const responseUsuarios = await ApiRequest().post('/api/editar', updatedBody);
	
			// Realizar la petición para editar en la tabla 'Jugador' con los datos actualizados
			const responseJugador = await ApiRequest().post('/api/editarJugador', updatedBody);
	
			// Verificar si ambas peticiones fueron exitosas antes de continuar
			if (responseUsuarios.status === 200 && responseJugador.status === 200) {
				// Actualizar el estado del formulario y mostrar un mensaje de éxito
				handleDialog();
				setBody(initialState);
				setMensaje({
					ident: new Date().getTime(),
					message: 'Datos editados exitosamente',
					type: 'success'
				});
				// Actualizar la lista de usuarios
				init();
			} else {
				// Manejar el caso de que alguna de las peticiones falle
				throw new Error('Hubo un error al editar los datos');
			}
		} catch ({ response }) {
			// Manejar errores de la respuesta del servidor
			setMensaje({
				ident: new Date().getTime(),
				message: response?.data?.sqlMessage || 'Hubo un error al editar los datos',
				type: 'error'
			});
		}
	};
	
	


	return (
		<>
			<Dialog maxWidth='sm' open={openDialog} onClose={handleDialog}>
				<DialogTitle>
					{isEdit ? 'Editar Usuario' : 'Crear Usuario'}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12}>
							<TextField
								margin='normal'
								name='Nombre_Usuario'
								value={body.Nombre_Usuario}
								onChange={onChange}
								variant='outlined'
								size='small'
								color='primary'
								fullWidth
								label='Nombre Usuario'
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								margin='normal'
								name='Contrasena'
								value={body.Contrasena}
								onChange={onChange}
								variant='outlined'
								size='small'
								color='primary'
								fullWidth
								label='Contraseña'
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								margin='normal'
								name='Nombre_Jugador'
								value={body.Nombre_Jugador}
								onChange={onChange}
								variant='outlined'
								size='small'
								color='primary'
								fullWidth
								label='Nombre Jugador'
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								margin='normal'
								name='Fecha_Nacimiento'
								type='date'
								value={body.Fecha_Nacimiento}
								onChange={onChange}
								variant='outlined'
								size='small'
								color='primary'
								fullWidth
								label='Fecha Nacimiento'
								InputLabelProps={{
									shrink: true, 
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
						<TextField
							select
							margin='normal'
							name='Rol'
							value={body.Rol}
							onChange={onChange}
							variant='outlined'
							size='small'
							color='primary'
							fullWidth
							label='Rol'
						>
							{roles.map((role) => (
								<MenuItem key={role.value} value={role.value}>
									{role.label}
								</MenuItem>
							))}
						</TextField>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant='text' color='primary' onClick={handleDialog}>cancelar</Button>
					<Button variant='contained' color='primary' onClick={isEdit ? () => onEdit() : () => onSubmit()}>guardar</Button>
				</DialogActions>
			</Dialog>
			<Page title="MEDIEVAL | Usuarios">
				<ToastAutoHide message={mensaje} />
				<Container maxWidth='xl'> {/* Ajustamos el maxWidth a 'xl' para hacerlo más grande */}
					<Box sx={{ pb: 0 }}>
						<Typography variant="h5" sx={{ mb: 4 }}>Lista de Usuarios</Typography>
					</Box>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Button onClick={handleDialog} startIcon={<AddOutlined />} variant='contained' color='primary' sx={{ height: '100%', width: '100%' }}>Nuevo Usuario</Button> {/* Ajustamos el tamaño del botón */}
						</Grid>
						<Grid item xs={12} sm={8} />
							<Grid item xs={14} sm={12}>
								<CommonTable
									data={usuariosList}
									columns={columns}
									sx={{
										width: '100%',
										'& .MuiDataGrid-cell': {
											fontSize: '1.2rem', // Ajusta el tamaño de fuente de las celdas
											padding: '16px', // Ajusta el espaciado interno de las celdas
										},
										'& .MuiDataGrid-row': {
											height: '60px', // Ajusta la altura de las filas
										},
									}}
								/>
								<br /><br />
							</Grid>
						</Grid>
				</Container>
			</Page>

		</>
	)
}

export default Usuarios

