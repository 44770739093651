// MainMenu.jsx
import React from 'react';
import { Drawer, Paper, IconButton, Divider, Button, Avatar, Typography } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import Logo from '../icon.png';
import { useStyles } from '../../../style/style';

const MainMenu = ({ open, setOpen, handleShowUsers }) => {
    const classes = useStyles();

    const handleOption1 = () => {
        // handleShowUsers(); // Esta función ahora se llama solo cuando se selecciona la opción "Gestión de Usuarios"
        console.log("ENTRA")
        setOpen(false); // Cerrar el menú al hacer clic en la opción "Gestión de Usuarios"
    };

    const handleDrawerClose = () => {
        setOpen(false); // Cerrar el menú al hacer clic en un elemento del menú
    };

    return (
        <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
            <Paper className={classes.paper} elevation={0}>
                <div className={classes.div}>
                    <IconButton edge='start' color='inherit' onClick={handleDrawerClose}>
                        <MenuIcon />
                    </IconButton>
                    <Avatar src={Logo} alt='Logo' className={classes.avatar} />
                    <Typography variant="h6">Medieval</Typography>
                </div>
                <Divider />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button variant='text' color='inherit' onClick={handleOption1}>Gestión de Usuarios</Button>
                    <Button variant='text' color='inherit'>Gestión de Contenidos</Button>
                    <Button variant='text' color='inherit'>Configuración del Sistema</Button>
                    <Button variant='text' color='inherit'>Monitoreo y Estadísticas</Button>
                    <Button variant='text' color='inherit'>Gestión de Roles</Button>
                    <Button variant='text' color='inherit'>Gestión de Informes</Button>
                </div>
            </Paper>
        </Drawer>
    );
};


export default MainMenu;
