import React, { useState } from 'react';
import { Grid, Container, Paper, Typography, TextField, Button, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import fondo from '../assets/img/fondoLogin.jpg';
import axios from 'axios';
import { useHistory } from 'react-router';
import customAvatarImage from '../assets/img/Login1.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${fondo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh'
  },
  container: {
    height: '60%',
    minHeight: '400px',
    marginTop: theme.spacing(10),
    [theme.breakpoints.down(400 + theme.spacing(2) + 2)]: {
      marginTop: 0,
      width: '100%',
      height: '100%'
    }
  },
  div: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  customAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: '50%', 
    backgroundImage: `url(${customAvatarImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const Login = () => {
  const [body, setBody] = useState({ username: '', password: '' });
  const { push } = useHistory();
  const classes = useStyles();
  const [error, setError] = useState(null);

  const inputChange = ({ target }) => {
    const { name, value } = target;
    setBody({
      ...body,
      [name]: value
    });
  };

  const onSubmit = () => {
    axios.post('//medieval-english-back.vercel.app/api/login', body)
      .then(({ data }) => {
        localStorage.setItem('auth', '"yes"');
  
        // Verificar el rol y redirigir
        if (data.rol === 'Admin') {
          // Redirigir a la ruta de administrador
         // alert('¡Bienvenido, administrador!');
          push('/Admin');
        } else {
          // Redirigir a la ruta del jugador
         // alert(data.rol);
        // alert('¡Bienvenido, jugador!');
          push('/Juego');
        }
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          setError('Error en el servidor. Inténtalo de nuevo más tarde.');
        } else {
          setError('Usuario o contraseña incorrectos.');
        }
      });
  };
  

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Container component={Paper} elevation={5} maxWidth='xs' className={classes.container}>
        <div className={classes.div}>
          <div className={classes.customAvatar}></div>
          <Typography component='h1' variant='h5'>Inicia Sesión</Typography>
          <form className={classes.form}>
            <TextField
              fullWidth
              autoFocus
              color='primary'
              margin='normal'
              variant='outlined'
              label='Username'
              value={body.username}
              onChange={inputChange}
              name='username'
            />
            <TextField
              fullWidth
              type='password'
              color='primary'
              margin='normal'
              variant='outlined'
              label='Password'
              value={body.password}
              onChange={inputChange}
              name='password'
            />
            <Button
              fullWidth
              variant='contained'
              color='secondary'
              className={classes.button}
              style={{ background: 'linear-gradient(90.21deg, #589446 -5.91%, #000000 111.58%)' }}
              onClick={onSubmit}
            >
              Sign In
            </Button>
          </form>
          {error && (
            <Typography variant='body2' color='error'>
              {error}
            </Typography>
          )}
        </div>
      </Container>
    </Grid>
  );
};

export default Login;
