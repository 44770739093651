import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharpprueba.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: { 
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>PROYECTO</h2>  
                        <p>Descripcion del proyecto: 
                          <br></br>El proyecto tiene como visión principal abordar la problemática del aprendizaje del inglés en niños de 10 a 12 años de manera innovadora y efectiva, aprovechando las ventajas de la tecnología y el juego educativo. La visión del proyecto se basa en los siguientes puntos clave:
                          <br></br> 1. Mejorar el aprendizaje del inglés desde tempranas edades: Reconociendo que aprender una segunda lengua es esencial en el mundo globalizado actual, se busca transformar la experiencia de aprendizaje del inglés en niños para que sea más efectiva y motivadora.
                          <br></br>2. Utilizar la tecnología como aliada: Se reconoce la importancia de incorporar la tecnología de manera efectiva en el proceso de enseñanza-aprendizaje. El proyecto aprovecha las posibilidades que brindan los juegos educativos y las plataformas en línea para hacer que el aprendizaje del inglés sea interactivo y atractivo.
                          <br></br>3.Fomentar la colaboración y la motivación: El proyecto busca crear un entorno de aprendizaje en el que los niños puedan colaborar entre sí, lo que fomenta la motivación y el interés por el aprendizaje del inglés. Además, se apunta a que los padres y docentes se involucren de manera activa en el proceso.
                          <br></br>4. Ofrecer una solución integral: El juego educativo de aventura tipo web que se desarrollará no solo abordará aspectos gramaticales y vocabulario del inglés, sino que también promoverá el desarrollo de habilidades sociales, cognitivas y creativas en los niños.
                          <br></br>5. Contribuir a la formación de ciudadanos globales: Al facilitar el aprendizaje del inglés, el proyecto contribuirá a que los niños estén mejor preparados para formar parte de la sociedad global y acceder a oportunidades laborales y culturales en un mundo cada vez más interconectado.
                          <br></br>En resumen, la visión del proyecto es transformar la manera en que los niños aprenden inglés, aprovechando la tecnología y el juego educativo para hacer que el proceso sea más efectivo, motivador y significativo, contribuyendo así a su desarrollo personal y a su preparación para el mundo globalizado.
                        </p>
                        <p>OBJETIVOS
                        <br>
                        </br>Apropiar y desarrollar conocimientos que permitan el uso estratégico de la tecnología informática en las organizaciones y tengan la posibilidad de crear productos y servicios competitivos que las diferencien de las demás a través del estudio permanente del entorno, el análisis organizacional, la gestión estratégica de tecnología informática y la investigación de los factores humanos que influyen para su adaptación.
                        <br>
                        </br><p>- Investigar, conocer, apropiar y generar conocimientos que permitan la formulación de metodologías para el diagnóstico y rediseño organizacional.</p> 
                        <br>
                        </br><p>-Conformar un observatorio de nuevas tecnologías informáticas que este en capacidad de investigarlas, conocerlas, apropiarlas y utilizarlas para proponer soluciones a los problemas que afectan las organizaciones. *Investigar, conocer, apropiar y generar conocimientos que permitan la formulación de metodologías para el planeamiento, análisis y diseño de sistema de información estratégicos para la organización. *Resaltar el desarrollo de los procesos de innovación tecnológica y su adecuada gestión para conseguir y mantener ventajas tecnológicas que incrementen la competitividad y la posición tecnológica de la empresa. *Identificar las funciones básicas que son necesarias desarrollar en la empresa para conseguir una gestión eficaz de la tecnología. *Difundir el conocimiento obtenido de las investigaciones del grupo a través de seminarios, el portal de METIS, una revista y un programa de radio.</p>
                        </p>   
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}