import React, { lazy } from 'react';
import { HomeRedirect } from '../routesAdmin/RouteUtils';
import RouteControllerAdmin from '../routesAdmin/RouteControllerAdmin';
import Dashboard from '../components/views/ADashboard';
const Login = lazy(() => import('../components/Login'));
const Home = lazy(() => import('../components/views/AHome'))

const routesAdmin = [
  {   
    path: '/',
    exact: true,  
    component: HomeRedirect,
  },
  {
    path: '/Login',
    exact: true,
    render: (props) => {
      //alert('Rendering Login component');
      return <Login {...props} />;
    },
  },
  {
    path: '/Admin',
    render: (props) => {
      return <RouteControllerAdmin component={Home} {...props} />;
    },
    roles: ['Admin'],
    routes: [  // Cambiar de routesAdmin a routes
      {
        path: '/Admin',
        exact: true,
        render: (props) => {
          return <RouteControllerAdmin component={Dashboard} {...props} />;
        },
        roles: ['Admin'],
      },
    ],
  },
];

export default routesAdmin;
