import React from 'react'
import {
	Card,
	LinearProgress
} from '@mui/material'
import { DataGrid, GridOverlay } from '@mui/x-data-grid'

function CustomLoadingOverlay() {
	return (
		<GridOverlay>
			<div style={{ position: 'absolute', top: 0, width: '100%' }}>
				<LinearProgress />
			</div>
		</GridOverlay>
	)
}

const CommonTable = ({ data, columns }) => {
	// Asegurar que cada objeto de fila tenga una propiedad 'id' única
	const dataWithIds = data.map((row, index) => ({
		...row,
		id: index + 1 // O utiliza algún otro valor único dependiendo de tu lógica
	}));

	return (
		<Card>
			<DataGrid
				autoHeight
				rows={dataWithIds}
				columns={columns}
				pageSize={10}
				rowsPerPageOptions={[10]}
				disableSelectionOnClick
				pagination
				components={{
					LoadingOverlay: CustomLoadingOverlay
				}}
			/>
		</Card>
	)
}


export default CommonTable
